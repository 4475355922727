<template>
    <div>
        <div class="row border" @click="toggleShowPortfolioItems()">
            <div class="col p-2">{{ portfolioStatus.name }}</div>
            <div class="col p-2" v-if="portfolioStatus.portfolioType == 0">{{ portfolioStatus.currentValue }} $</div>
            <div class="col p-2" v-if="portfolioStatus.portfolioType == 0">{{ portfolioStatus.cash }} $</div>
            <div class="col p-2" v-if="portfolioStatus.portfolioType == 0">{{ portfolioStatus.totalCurrentValue }} $</div>
            <div class="col p-2">{{ portfolioStatus.progressSinceStart }}</div>
            <div class="col p-2" v-if="portfolioStatus.portfolioType == 0"><input type="checkbox" v-model="portfolioStatus.invertStrategy"/></div>
            <div class="col p-2" v-if="portfolioStatus.portfolioType == 0">{{ portfolioStatus.nextEvaluatioDate }}</div>
            <div class="col p-2" v-if="portfolioStatus.portfolioType == 0">{{ portfolioStatus.daysBetweenEvaluation }}</div>
            <div class="col p-2">{{ portfolioStatus.startDate }}</div>
            <div class="col p-2" v-if="portfolioStatus.portfolioType == 1">
                <router-link :to="{ name: 'StockGraph', query: { id: portfolioStatus.id, name: portfolioStatus.name, portfolioItemId: -1, graphType: 'portfolio', hasVolume: false } }"
                             :key="portfolioStatus.id" target="_blank">
                    <fa icon="chart-line" class="fas fa-chart-line fa-2x"></fa>
                </router-link>
            </div>
        </div>
        <div class="row" v-if="state.showPortfolioItems">
            <div class="col-1"></div>
            <div class="col-11 p-3">
                <div class="row border">
                    <div class="col p-2"><b>Stock Name</b></div>
                    <div class="col p-2"><b>Symbol</b></div>
                    <div class="col p-2"><b>Current Price</b></div>
                    <div class="col p-2"><b>Start Price</b></div>
                    <div class="col p-2"><b>Progress</b></div>
                    <div class="col p-2"><b>Holding</b></div>
                    <div class="col p-2"><b>Buy Date</b></div>
                    <div v-if="state.isLoggedIn" class="col p-2"><b>Suppress</b></div>
                    <div v-if="state.isLoggedIn" class="col p-2"><b>Follow up</b></div>
                </div>
                <PortfolioItem v-for="portfolioItem in state.portfolioItems"
                               :key="portfolioItem.id"
                               :portfolioItem="portfolioItem">
                </PortfolioItem>
            </div>
        </div>
    </div>
</template>

<script>
    import { reactive } from 'vue';
    import $ from 'jquery'
    import core from '../api/core'
    import PortfolioItem from "../components/PortfolioItem.vue";
	import store from '../store'

    export default {
        name: "PortfolioStatus",
        components: { PortfolioItem },
        props: {
            portfolioStatus:
            {
                type: Object,
                default: null
            },
            portfolioId: Number
        },
        setup({ portfolioStatus, portfolioId }) {
            const state = reactive({
                showPortfolioItems: false,
                portfolioItems: null,
				isLoggedIn: false
            });

			state.isLoggedIn = store.state.accessToken !== undefined;

            function fetchPortfolioItems() {
                var ajaxSettings = core.buildAjaxSettings('portfolios/' + portfolioId + '/items', false);
                $.getJSON(ajaxSettings, function (pi) {
                    state.portfolioItems = pi
                });
            }

            function toggleShowPortfolioItems() {
                if (state.portfolioItems === null) {
                    fetchPortfolioItems();
                }
                state.showPortfolioItems = !state.showPortfolioItems;
            }

            return {
                state,
                portfolioStatus,
                toggleShowPortfolioItems
            };
        }
    };
</script>

