<template>
    <header>
        <nav class="navbar"
             role="navigation"
             aria-label="main navigation">
            <HeaderBarBrand />
            <HeaderBarLinks />
        </nav>
    </header>
</template>

<script>
    import HeaderBarBrand from '@/components/header-bar-brand';
    import HeaderBarLinks from '@/components/header-bar-links';

    export default {
        name: 'HeaderBar',
        components: { HeaderBarBrand, HeaderBarLinks },
    };
</script>
