<template>
    <div class="row border" v-if="!state.deleted">
        <div class="col p-2">
            <div v-if="!state.edit">{{ strategyItem.factor }}</div>
            <div v-if="state.edit">
                <input type="number" id="factor" name="factor" v-model="strategyItem.factor" />
            </div>
        </div>
        <div class="col p-2">
            <div v-if="!state.edit">{{ core.getRepetitionTypesText(strategyItem.dayToCheckGain) }}</div>
            <select v-if="state.edit" v-model="strategyItem.dayToCheckGain" class="align-middle">
                <option v-for="repetitionTypesText in store.state.repetitionTypesTexts" v-bind:key="repetitionTypesText.key" v-bind:value="repetitionTypesText.key">
                    {{ repetitionTypesText.value }}
                </option>
            </select>
        </div>
        <div class="col p-2">
            <fa v-on:click="deleteStrategyItem()" icon="trash-alt" class="m-2 fas fa-trash-alt fa-2x"></fa>
            <fa v-on:click="editStrategyItem()" v-if="!state.edit" icon="pen" class="m-2 fas fa-pen fa-2x"></fa>
            <fa v-on:click="saveStrategyItem()" v-if="state.edit" icon="save" class="m-2 fas fa-save fa-2x"></fa>
        </div>
    </div>
</template>

<script>
    import { reactive } from 'vue';
    import core from '../api/core';
    import store from '../store';
    import $ from 'jquery';

    export default {
        name: "PortfolioItem",
        props: {
            strategyItem: null
        },
        setup({ strategyItem }) {
            const state = reactive({
                deleted: false,
                edit: false
            });

            if (strategyItem.id === -1) {
                state.edit = true;
            }

            function deleteStrategyItem() {
                var ajaxSettings = core.buildAjaxSettings('strategies/items/' + this.strategyItem.id, false);
                ajaxSettings.type = 'DELETE';
                $.getJSON(ajaxSettings, function () {
                    state.deleted = true
                });
            }

            function editStrategyItem() {
                state.edit = true;
            }

            function saveStrategyItem() {
                strategyItem.factor = parseFloat(strategyItem.factor);
                //console.log(strategyItem);
                var ajaxSettings = core.buildAjaxSettings('strategies/items', false);

                if (strategyItem.id === -1) {
                    ajaxSettings.type = 'POST';
                }
                else {
                    ajaxSettings.type = 'PUT';
                }

                ajaxSettings.data = JSON.stringify(strategyItem);
                $.getJSON(ajaxSettings, function () {
                    state.edit = false;
                });
            }

            return {
                deleteStrategyItem,
                editStrategyItem,
                saveStrategyItem,
                strategyItem,
                core,
                state,
                store
            };
        }
    };
</script>
