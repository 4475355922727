<template>
    <div class="row border">
        <div class="col p-2">
            <router-link :to="{ name: 'StockGraph', query: { id: portfolioItem.stockId, name: portfolioItem.stockName, portfolioItemId: portfolioItem.id, graphType: 'stock', hasVolume: true } }"
                         :key="portfolioItem.stockId" target="_blank">
                {{ portfolioItem.stockName }}
            </router-link>
        </div>
        <div class="col p-2 btn btn-link" @click="gotoNasdaq()">{{ portfolioItem.stockSymbol }}</div>
        <div class="col p-2">{{ portfolioItem.currentPrice }}</div>
        <div class="col p-2">{{ portfolioItem.startPrice }}</div>
        <div class="col p-2">{{ portfolioItem.progress }}</div>
        <div class="col p-2">{{ Math.round(portfolioItem.holding) }}</div>
        <div class="col p-2">{{ portfolioItem.buyDate }}</div>
        <div v-if="state.isLoggedIn" class="col p-2"><input type="checkbox" v-model="suppress" @change="check($event)" /></div>
        <fa v-if="state.isLoggedIn" v-on:click="addFolllowup()" icon="briefcase" class="col fas fa-briefcase fa-2x"></fa>
    </div>
</template>

<script>
	import { reactive } from 'vue';
    import core from '../api/core'
    import store from '../store'
    import $ from 'jquery'

    export default {
        name: "PortfolioItem",
        props: {
            portfolioItem: null
        },
        setup({ portfolioItem }) {
			const state = reactive({
				isLoggedIn: false
            });

            state.isLoggedIn = store.state.accessToken !== undefined;

            function gotoNasdaq() {
                var url = "https://www.nasdaq.com/market-activity/stocks/" + portfolioItem.stockSymbol;
                var win = window.open(url, '_blank');
                win.focus();
            }

            function check() {
                suppress = !suppress;
                //console.log(suppress);
                core.doSuppress(portfolioItem.stockId, suppress);
            }

            function addFolllowup() {
                var buyDate = new Date();
                buyDate.setDate(buyDate.getDate() - 1);

                var dd = String(buyDate.getDate()).padStart(2, '0');
                var mm = String(buyDate.getMonth() + 1).padStart(2, '0');
                var yyyy = buyDate.getFullYear();

                var newPortfolioItem = {
                    stockId: portfolioItem.stockId,
                    currentPrice: portfolioItem.currentPrice,
                    holding: 10000 / portfolioItem.currentPrice,
                    buyDate: yyyy + '-' + mm + '-' + dd,
                    portfolioId: store.state.selectedFollowupPortfolioId
                };

                var ajaxSettings = core.buildAjaxSettings('portfolios/' + store.state.selectedFollowupPortfolioId + '/items', false);
                ajaxSettings.type = 'POST';
                ajaxSettings.data = JSON.stringify(newPortfolioItem);
                $.ajax(ajaxSettings, function () {
                    core.fetchFollowupPortfolios();
                });
            }

            var suppress = core.suppressStock(portfolioItem.stockId);

            return {
                addFolllowup,
                portfolioItem,
                suppress,
                gotoNasdaq,
                check,
                state
            };
        }
    };
</script>
