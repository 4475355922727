<template>
	<div>
		<ul class="nav navbar-nav navbar-right">
			<li>
				<fa v-if="!account" @click="SignIn" icon="sign-in-alt" class="fas fa-sign-in-alt fa-2x"/>
				<fa v-else @click="SignOut" icon="sign-out-alt" class="fas fa-sign-out-alt fa-2x"/>
			</li>
			<li v-if="account">{{ account.name }}</li>
		</ul>
	</div>
</template>

<script>
	import { PublicClientApplication } from '@azure/msal-browser';
	import store from '../store';
	import { VueCookieNext } from 'vue-cookie-next';


	export default {
		name: 'HeaderBar',
		data() {
			return {
				account: undefined,
			};
		},
		async created() {
			this.$msalInstance = new PublicClientApplication(
				store.state.msalConfig,
			);
		},
		mounted() {
			const accounts = this.$msalInstance.getAllAccounts();
			if (accounts.length == 0) {
				return;
			}
			this.account = accounts[0];
			this.$emitter.emit('login', this.account);
		},
		methods: {
			async SignIn() {
				await this.$msalInstance
					.loginPopup({})
					.then(() => {
						const myAccounts = this.$msalInstance.getAllAccounts();
						this.account = myAccounts[0];
						this.$emitter.emit('login', this.account);
					})
					.catch(error => {
						console.error(`error during authentication: ${error}`);
					});
			},
			async SignOut() {
				VueCookieNext.setCookie('hasLoggedIn', false);
				await this.$msalInstance
					.logout({})
					.then(() => {
						this.$emitter.emit('logout', 'logging out');
					})
					.catch(error => {
						console.error(error);
					});
			},
		},
	};
</script>
