<template>
    <div class="row border">
        <div class="col-4 p-2 text-left">
            <router-link :to="{ name: 'StockGraph', query: { id: stock.stockId, name: stock.name, portfolioItemId: -1, graphType: 'stock' } }"
                         :key="stock.stockId" target="_blank">
                {{ stock.name }}
            </router-link>
        </div>
        <div class="col-1 p-2 text-left btn btn-link" @click="gotoNasdaq()">{{ stock.symbol }}</div>
        <div class="col-3 p-2 text-left">{{ stock.exchange }}</div>
        <div class="col-1 p-2 text-left">{{ stock.issueType }}</div>
        <div class="col-1 p-2 text-left">{{ stock.currency }}</div>
        <div class="col-1 p-2 text-left">{{ stock.source }}</div>
        <div class="col-1 p-2"><input type="checkbox" v-model="suppress" @change="check($event)" /></div>
    </div>
</template>

<script>
    import core from '../api/core'

    export default {
        name: "Stock",
        props: {
            stock: null,
            suppress: Boolean
        },
        setup({ stock, suppress }) {
            function gotoNasdaq() {
                var url = "https://www.nasdaq.com/market-activity/stocks/" + stock.symbol;
                var win = window.open(url, '_blank');
                win.focus();
            }

            function check() {
                //console.log(this.suppress);
                core.doSuppress(this.stock.stockId, this.suppress);
            }

            return {
                stock,
                suppress,
                gotoNasdaq,
                check
            };
        }
    };
</script>