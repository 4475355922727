<template>
    <h1>Portfilio</h1>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Portfolio',
  components: {
  }
}
</script>
