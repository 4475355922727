<template>
    <div id="app">
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
        <HeaderBar />
        <!--<div class="mt-4">
            <img alt="Balldinvent" src="./assets/Balldinvent.png">
        </div>-->
        <div id="nav" class="mb-1">
            <div class="d-inline m-1" v-if="state.isLoggedIn">
                <router-link to="/0">Strategy Portfolios</router-link>
            </div>
            <div class="d-inline m-1" v-if="state.isLoggedIn">|</div>
            <div class="d-inline m-1">
                <router-link to="/1">Follow up Portfolios</router-link>
            </div>
            <div class="d-inline m-1" v-if="state.isLoggedIn">|</div>
            <div class="d-inline m-1" v-if="state.isLoggedIn">
                <router-link to="/stocks">Stocks</router-link>
            </div>
            <div class="d-inline m-1" v-if="state.isLoggedIn">|</div>
            <div class="d-inline m-1" v-if="state.isLoggedIn">
                <router-link to="/strategies">Strategies</router-link>
            </div>
            <div class="d-inline m-1" v-if="state.isLoggedIn">|</div>
            <div class="d-inline m-1" v-if="state.isLoggedIn">
                <router-link to="/macro">Macro Data</router-link>
            </div>
        </div>
    <router-view />
    </div>
</template>


<script>
	import { reactive } from 'vue';
    import HeaderBar from '@/components/header-bar';
    import core from './api/core';
	import store from './store'


    export default {
        name: 'App',
        components: { HeaderBar },
        setup() {
			const state = reactive({
				isLoggedIn: false
			});

            core.getAccessToken().then(() => {
                core.fetchSuppressed();
				core.fetchStrategyPortfolios();
                state.isLoggedIn = store.state.accessToken !== undefined;
            });

            core.fetchRepetitionTypesTexts();
            core.fetchStrategyTypeTexts();
            core.fetchExchanges();

            core.fetchFollowupPortfolios();

			return {
				state
			};
        }
    }
</script>


<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #000000;
		background-color: lightgray; /* For browsers that do not support gradients */
		background-image: linear-gradient(to bottom, lightgrey, white);
	}

    #nav {
        padding: 30px;
    }

        #nav a {
            font-weight: bold;
            color: #2c3e50;
        }

            #nav a.router-link-exact-active {
                color: #42b983;
            }
</style>
