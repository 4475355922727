<template>
    <vue-highcharts     
        type="chart"
        :options="chartOptions"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        @rendered="onRender"/>
</template>

<script>
    import { useRoute } from 'vue-router';
    import core from '../api/core'
    import $ from 'jquery'
    import VueHighcharts from 'vue3-highcharts';
    import { ref, computed } from 'vue';

    export default {
        name: "StockGraph",
        components: {
            VueHighcharts
        },
        setup() {
            var route = useRoute();
            var id = route.query.id;
            var name = route.query.name;
            var portfolioItemId = route.query.portfolioItemId;
			var graphType = route.query.graphType;
            var hasVolume = route.query.hasVolume;

            console.log('portfoliotId: ' + portfolioItemId);
            console.log('name: ' + name);
			console.log('graphType: ' + graphType);

            var quoteData = null;
            var strategyData = null;
            var oneMonthBack = new Date(Date.now() - 40 * 86400000);
            var fromDate = oneMonthBack.getFullYear() + '-' + (oneMonthBack.getMonth() + 1) + '-' + oneMonthBack.getDate();

			if (graphType === 'stock') {
                var ajaxSettingsQuotes = core.buildAjaxSettings('stocks/' + id + '/quotes?fromDate=' + fromDate, false);
                $.getJSON(ajaxSettingsQuotes, function (qs) {
                    quoteData = qs;
                });

                if (portfolioItemId >= 0) {
                    var ajaxSettingsStrategies = core.buildAjaxSettings('portfolios/' + portfolioItemId + '/strategydates', false);
                    $.getJSON(ajaxSettingsStrategies, function (s) {
                        strategyData = s;
                        console.log(strategyData);
                    });
                }
            }
			else if (graphType === 'portfolio') {
                //console.log('graphType: ' + graphType);
                var ajaxSettingsFollowupQuotes = core.buildAjaxSettings('portfolios/' + id + '/followupProgress', false);
                $.getJSON(ajaxSettingsFollowupQuotes, function (qs) {
                    quoteData = qs;
                });
            }
			else if (graphType === 'velocityofmoney') {
				console.log('graphType: ' + graphType);
				var ajaxSettingsVelocityOfMoney = core.buildAjaxSettings('macro/velocityofmoney', false);
				$.getJSON(ajaxSettingsVelocityOfMoney, function (qs) {
					quoteData = qs;
				});
			}

            const quotes = ref(quoteData.map(v => v.quote));
            const volumes = ref(quoteData.map(v => v.volume));
            const timeStamps = ref(quoteData.map(v => v.timeStamp));

            var options = {
                chart: {
                    type: 'line',
                },
                title: {
                    text: name,
                },
                xAxis: {
                    categories: timeStamps.value,
                },
                yAxis: [{
                    title: {
						text: graphType === 'stock' || graphType === 'velocityofmoney' ? 'Quote' : 'Percent',
                    },
                }],
                series: [{
                    yAxis: 0,
                    type: 'line',
					name: graphType === 'stock' || graphType === 'velocityofmoney' ? 'Quotes' : 'Progress',
                    data: quotes.value
                }]
            };

            if (hasVolume === 'true') {
                options.yAxis.push({
                    title: {
                        text: 'Volume',
                    },
                    opposite: true
                });
                options.series.push({
                    yAxis: 1,
                    type: 'column',
                    name: 'Volumes',
                    data: volumes.value
                });
            }

            const chartOptions = computed(() => options);


            const onRender = () => {
                //console.log('Chart rendered');
            };

            return {
                chartOptions,
                onRender,
            };
        }
    };
</script>

<style>
    .vue-highcharts {
        width: 100%;
    }
</style>