import { PublicClientApplication } from '@azure/msal-browser';
import $ from 'jquery'
import store from '../store'
import { VueCookieNext } from 'vue-cookie-next';

//const _httpBaseAddress = "https://localhost:44390/api/v1/";
const _httpBaseAddress = "https://balldinvest-prod.azurewebsites.net/api/v1/";

export default {
	buildAjaxSettings(restOperation, asyncOperation = true) {
		var ajaxSettings = {
			dataType: "json",
			contentType: 'application/json',
			url: _httpBaseAddress + restOperation,
			processData: true,
			async: asyncOperation,
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
				'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type',
				'Authorization': 'Bearer ' + store.state.accessToken
			}
		};

		//console.log('Access token (ajax settings): ' + store.state.accessToken);

		return ajaxSettings;
	},
	async getAccessToken() {
		var hasLoggedIn = VueCookieNext.getCookie('hasLoggedIn');
		if (hasLoggedIn === 'true') {
			let request = {
				scopes: ["https://viftydog.onmicrosoft.com/ee9e4aa8-20a0-46c5-9956-fdf503b07335/Admin"]
			};
			const msalInstance = new PublicClientApplication(
				store.state.msalConfig,
			);
			try {
				let tokenResponse = await msalInstance.acquireTokenSilent(request);
				store.state.accessToken = tokenResponse.accessToken;
			} catch (error) {
				//console.error('Silent token acquisition failed. Using interactive mode');
				let tokenResponse = await msalInstance.acquireTokenPopup(request);
				//console.log(`Access token acquired via interactive auth ${tokenResponse.accessToken}`)
				store.state.accessToken = tokenResponse.accessToken;
			}
			msalInstance.setActiveAccount(store.state.accessToken);
			//console.log(store.state.accessToken);
			if (store.state.accessToken !== undefined) {
				VueCookieNext.setCookie('hasLoggedIn', true);
			}
			else
			{
				VueCookieNext.setCookie('hasLoggedIn', false);
			}
		}
	},



	suppressStock(stockId) {
		if (store.state.suppressed !== null) {
			var foundStock = store.state.suppressed.find(function (s) {
				return s.stockId === stockId;
			});
			var isInSuppressList = foundStock !== undefined;

			return isInSuppressList;
		}
	},
	doSuppress(stockId, suppressed) {
		//console.log('stockId: ' + stockId + ' suppressed: ' + suppressed);
		var isCurrentlySuppressed = this.suppressStock(stockId);
		if (isCurrentlySuppressed && !suppressed) {
			// remove from suppressed
			var removeIndex = store.state.suppressed.map(function (item) { return item.stockIdd; }).indexOf(stockId);
			store.state.suppressed.splice(removeIndex, 1);
		}
		if (!isCurrentlySuppressed && suppressed) {
			store.state.suppressed.push({ stockId: stockId });
		}
		var ajaxSettingsSuppress = this.buildAjaxSettings('stocks/' + stockId + '/suppress?suppress=' + suppressed, false);
		ajaxSettingsSuppress.type = 'PUT';
		$.ajax(ajaxSettingsSuppress, function () {
		});
	},
	getRepetitionTypesText(key) {
		var text = store.state.repetitionTypesTexts.find(function (t) {
			return t.key === key;
		});
		if (text !== undefined) {
			return text.value;
		}
		return "";
	},

	fetchSuppressed() {
		var ajaxSettingsSuppress = this.buildAjaxSettings('stocks/suppress', true);
		$.getJSON(ajaxSettingsSuppress, function (s) {
			store.state.suppressed = s;
		});
	},
	fetchStrategyPortfolios() {
		var ajaxSettingsPortfolioType0 = this.buildAjaxSettings('portfolios/strategies', true);
		$.getJSON(ajaxSettingsPortfolioType0, function (ps) {
			store.state.strategyPortfolios = ps;
		});
	},
	fetchFollowupPortfolios() {
		var ajaxSettingsPortfolioType1 = this.buildAjaxSettings('portfolios/followups', true);
		$.getJSON(ajaxSettingsPortfolioType1, function (ps) {
			store.state.followupPortfolios = ps;
			store.state.emitter.emit('followupsLoaded');
		});
	},
	fetchRepetitionTypesTexts() {
		var ajaxSettings = this.buildAjaxSettings('data/repetitiontypes', true);
		$.getJSON(ajaxSettings, function (ps) {
			store.state.repetitionTypesTexts = ps;
		});
	},
	fetchStrategyTypeTexts() {
		var ajaxSettings = this.buildAjaxSettings('data/strategytypes', true);
		$.getJSON(ajaxSettings, function (ps) {
			store.state.strategyTypesTexts = ps;
		});
	},
	fetchExchanges() {
		var ajaxSettings = this.buildAjaxSettings('data/exchanges', true);
		$.getJSON(ajaxSettings, function (ps) {
			store.state.exchanges = ps;
		});
	}
}