<template>
	<div class="container">
		<h1>Macro Data</h1>

		<router-link :to="{ name: 'StockGraph', query: { id: -1, name: 'Velocity Of Money', portfolioItemId: -1, graphType: 'velocityofmoney', hasVolume: false } }"
						:key="-1" target="_blank">
			Velocity Of Money
		</router-link>
	</div>
</template>

<script>
	export default {
		name: 'Macro',
		components: {
		}
	}
</script>
