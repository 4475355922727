<template>
    <div class="container">
        <div class="row p-2">
            <div class="col-1">Serach:</div>
            <div class="col-2"><input type="text" @change="searching" /></div>
        </div>
        <div class="row border">
            <div class="col-4 p-2 text-left"><b>Name</b></div>
            <div class="col-1 p-2 text-left"><b>Symbol</b></div>
            <div class="col-3 p-2 text-left"><b>Exchange</b></div>
            <div class="col-1 p-2 text-left"><b>Issue Type</b></div>
            <div class="col-1 p-2 text-left"><b>Currency</b></div>
            <div class="col-1 p-2 text-left"><b>Source</b></div>
            <div class="col-1 p-2"><b>Supress</b></div>
        </div>
        <Stock  v-for="stock in state.stocks"
                :key="stock.stockId"
                :stock="stock"
                :suppress="suppressStock(stock.stockId)">
        </Stock>
    </div>
</template>

<script>
	import { reactive } from 'vue';
    import $ from 'jquery'
    import core from '../api/core'
    import Stock from "../components/Stock.vue";

    export default {
        name: "Stocks",
        components: { Stock },
        setup() {
			const state = reactive({
				stocks: [],
                searchValue: ''
            });

            function suppressStock(stockId) {
                return core.suppressStock(stockId);
            }

            function searching({ target }) {
                state.searchValue = target.value;
				console.log(state.searchValue);

                if (state.searchValue.length > 1) {
                    state.stocks = [];
					var ajaxSettingsStocks = core.buildAjaxSettings('stocks?search=' + state.searchValue, true);
					$.getJSON(ajaxSettingsStocks, function (s) {
						state.stocks = s;
					});
				}
            }

            return {
                state,
                suppressStock,
				searching
            }
        }
    };
</script>
