<template>
    <div>
        <div class="row border" @click="toggleShowStrategyItems()">
            <div class="col p-2">{{ strategy.name }}</div>
            <div class="col p-2">{{ strategy.exchanges }}</div>
            <div class="col p-2">{{ strategy.repetition }}</div>
            <div class="col p-2">{{ strategy.slidingAverageWindow }}</div>
            <div class="col p-2">{{ strategy.strategyType }}</div>
        </div>
        <div class="row" v-if="state.showStrategyItems">
            <div class="col-1"></div>
            <div class="col-11 p-3">
                <div class="row border">
                    <div class="col p-2"><b>Factor</b></div>
                    <div class="col p-2"><b>Day To Check Gain</b></div>
                    <div class="col p-2">
                        <fa v-on:click="addStrategyItem()" icon="plus-square" class="fas fa-plus-square fa-2x"></fa>
                    </div>
                </div>
                <StrategyItem v-for="strategyItem in state.strategyItems"
                               :key="strategyItem.id"
                               :strategyItem="strategyItem">
                </StrategyItem>
            </div>
        </div>
    </div>
</template>

<script>
    import { reactive } from 'vue';
    import $ from 'jquery'
    import core from '../api/core'
    import StrategyItem from "../components/StrategyItem.vue";

    export default {
        name: "Strategy",
        components: { StrategyItem },
        props: {
            strategy: null,
            strategyId: Number
        },
        setup({ strategy, strategyId }) {
            const state = reactive({
                showStrategyItems: false,
                strategyItems: null,
                strategyId: strategyId
            });

            function fetchStrategyItems() {
                var ajaxSettings = core.buildAjaxSettings('strategies/' + strategyId + '/items', false);
                $.getJSON(ajaxSettings, function (pi) {
                    state.strategyItems = pi
                });
            }

            function toggleShowStrategyItems() {
                if (state.strategyItems === null) {
                    fetchStrategyItems();
                }
                state.showStrategyItems = !state.showStrategyItems;
            }

            function addStrategyItem() {
                var strategyItem = {
                    id: -1,
                    strategyId: state.strategyId,
                    factor: 1,
                    dayToCheckGain: 0
                };
                state.strategyItems.unshift(strategyItem);
            }

            return {
                toggleShowStrategyItems,
                addStrategyItem,
                state,
                strategy
            };
        }
    };
</script>