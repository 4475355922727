<template>
    <div class="container">
        <div class="row">
            <p>
                The listed portfolios on this page have all underlaying stocks that build up the portfolio. Click on each row to see what stocks those are.
            </p>
        </div>
        <div class="row">
            <p>
                The portfolio progress is calculated as an average of each stocks progress and not based on the aggregated portfolios growth. 
                Therefor the total value will grow as new stocks are added, for now. In the future the portfolio will be treated as a portfolio 
                with preset budget. This means that, in the future, when buying new stocks those performing the worst will be sold off. 
                This is not the case in the current solution.
            </p>
        </div>
        <div class="row p-2">
            <div class="col-9"></div>
            <div class="col-1">
                <fa v-if="state.portfolioType==1 && state.isLoggedIn" v-on:click="entering()" icon="plus-square" class="fas fa-plus-square fa-2x"></fa>
            </div>
            <div class="col-2" v-if="state.isLoggedIn">
                <select @change="followupPortfolioSelected()" v-model="state.selectedFollowupPortfolioId" class="align-middle">
                    <option v-for="portfolio in state.followupPortfolios" v-bind:key="portfolio.id" v-bind:value="portfolio.id">
                        {{ portfolio.name }}
                    </option>
                </select>
            </div>
        </div>

        <div v-if="state.entering" class="row p-2 text-center">
            <div class="col-4">
                <label class="text-left m-2 float-left">Name:</label>
                <input v-model="state.name" class="m-2 text-left float-left">
            </div>
            <div class="col-4">
                <label class="text-left m-2 float-left">Description:</label>
                <input v-model="state.description" class="m-2 text-left float-left">
            </div>
            <div class="col-3">
                <button type="button" class="btn btn-primary m-2 float-right" v-on:click="cancel()">Cancel</button>
                <button type="button" class="btn btn-primary m-2 float-right" v-on:click="add()">Add</button>
            </div>
        </div>

        <div class="row border">
            <div class="col p-2"><b>Name</b></div>
            <div class="col p-2" v-if="state.portfolioType == 0"><b>Current Value</b></div>
            <div class="col p-2" v-if="state.portfolioType == 0"><b>Cash</b></div>
            <div class="col p-2" v-if="state.portfolioType == 0"><b>Total Value</b></div>
            <div class="col p-2"><b>Progress</b></div>
            <div class="col p-2" v-if="state.portfolioType == 0"><b>Invert Strategy</b></div>
            <div class="col p-2" v-if="state.portfolioType == 0"><b>Next Evaluatio Date</b></div>
            <div class="col p-2" v-if="state.portfolioType == 0"><b>Days Between Evaluation</b></div>
            <div class="col p-2"><b>Start date</b></div>
            <div class="col p-2" v-if="state.portfolioType == 1"></div>
        </div>
        <PortfolioStatus v-for="portfolioStatus in state.portfolioStatuses"
                            :key="portfolioStatus.id"
                            :portfolioId="portfolioStatus.id"
                            :portfolioStatus="portfolioStatus">
        </PortfolioStatus>
    </div>
</template>

<script>
    import { reactive } from 'vue';
    import { useRoute } from 'vue-router';
    import core from '../api/core'
    import $ from 'jquery'
    import store from '../store'
    import PortfolioStatus from "../components/PortfolioStatus.vue";

    export default {
        name: "Portfilios",
        components: { PortfolioStatus },
        beforeRouteUpdate(to, from, next) {
            this.setFollowUpPortfolios(to.params.portfolioType);
            next();
        },
        setup() {
            var route = useRoute();
            var portfolioType = route.params.portfolioType;

            const state = reactive({
                portfolioStatuses: null,
                selectedFollowupPortfolioId: Number,
                portfolioType: Number,
                entering: false,
                name: "",
                description: "",
                followupPortfolios: null,
				isLoggedIn: false
            });

			state.isLoggedIn = store.state.accessToken !== undefined;

            setFollowUpPortfolios(portfolioType);

            function setFollowUpPortfolios(portfolioType) {
                state.followupPortfolios = store.state.followupPortfolios;
                state.portfolioType = portfolioType;
                if (portfolioType == 0) {
                    state.portfolioStatuses = store.state.strategyPortfolios;
                }
                else if (portfolioType == 1) {
                    state.portfolioStatuses = store.state.followupPortfolios;
                }
            }

            function entering() {
                state.entering = true;
            }

            function cancel() {
                state.entering = false;
            }

            function add() {
                var portfolio = {
                    name: state.name,
                    description: state.description,
                    portfolioType: 1,
                    initialValue: 0,
                    active: true,
                    numberOfStocks: 0,
                    strategyId: 27
                };

                //console.log(portfolio);

                var ajaxSettings = core.buildAjaxSettings('portfolios', false);
                ajaxSettings.type = 'POST';
                ajaxSettings.data = JSON.stringify(portfolio);
                $.ajax(ajaxSettings, function () {
                    core.fetchFollowupPortfolios();
                    state.name = '';
                    state.description = '';
                    state.entering = false;
                });
            }

            function followupPortfolioSelected() {
                store.state.selectedFollowupPortfolioId = state.selectedFollowupPortfolioId;
            }

            return {
                followupPortfolioSelected,
                setFollowUpPortfolios,
                entering,
                cancel,
                add,
                state,
                core
            }
        }
    };
</script>
