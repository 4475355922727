import { createStore } from 'vuex';

export default createStore({
	state: {
		suppressed: null,
		strategyPortfolios: null,
		followupPortfolios: null,
		selectedFollowupPortfolioId: 54,
		repetitionTypesTexts: null,
		strategyTypesTexts: null,
		exchanges: null,
		msalConfig: {
			auth: {
				clientId: '2d91d54a-5c38-4eaa-ad31-091a87818616',
				authority:
					'https://login.microsoftonline.com/6090368d-669b-4763-b975-7fc7395012a1'
			},
			cache: {
				cacheLocation: 'localStorage'
			}
		},
		accessToken: undefined,
		emitter: undefined
	},
	mutations: {},
	actions: {},
	modules: {}
})
