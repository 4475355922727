<template>
	<div class="content-container">
		<div class="section content-title-group">
			<h3 class="title">Welcome to Balldinvent</h3>
			<div class="container">
				<div class="row p-2">
					<div class="col-2" />
					<div class="col-8">
						<p>
							Balldinvent is a tool that automatically finds stocks that suddenly makes a move in value, upwards, in either price or volume. 
							It collects stock quotes every day. After the collection of quotes, a sophisticated algorithm starts and finds stocks that 
							have shown an abnormal increase in its value.
						</p>
						<p>
							After the algorithm has processed all stocks and selected candidates, a human goes in and selects, among those candidates, 
							stocks of particular interest. Under <router-link to="/1">Follow up Portfolios</router-link> you can see the portfolios
							that are the result of the human intervention described above.
						</p>
					</div>
					<div class="col-2" />
				</div>
			</div>

			<h3>Portfolio Progress</h3>
			<br />
			<div class="container p-2">
				<div class="row p-2">
					<div class="col-12">
						<b>Highest performing portfolio</b>
					</div>
				</div>
				<div class="row p-2">
					<div class="col-3" />
					<div class="col-3">
						<div>Progress:</div>
						<div>Start date:</div>
					</div>
					<div class="col-3">
						<div><b>{{topPercent}}</b></div>
						<div><b>{{topStartDate}}</b></div>
					</div>
					<div class="col-3" />
				</div>
				<div class="row p-2"/>
				<div class="row p-2">
					<div class="col-12">
						<b>Lowest performing portfolio</b>
					</div>
				</div>
				<div class="row p-2">
					<div class="col-3" />
					<div class="col-3">
						<div>Progress:</div>
						<div>Start date:</div>
					</div>
					<div class="col-3">
						<div><b>{{bottomPercent}}</b></div>
						<div><b>{{bottomStartDate}}</b></div>
					</div>
					<div class="col-3" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	//import customTokenCredential from '../CustomTokenProvider';
	import store from '../store';
	import core from '../api/core';
	import { VueCookieNext } from 'vue-cookie-next';

	export default {
		name: 'Balldinvest',
		data() {
			return {
				account: undefined,
				topPercent: '-',
				bottomPercent: '-',
				topStartDate: '-',
				bottomStartDate: '-'
			};
		},
		async created() {
			this.$emitter.on('followupsLoaded', () => {
				var orderedByProgressFollowupPortfolios = store.state.followupPortfolios.sort((a, b) => a.progressSinceStartForSort < b.progressSinceStartForSort ? 1 : -1);
				this.topPercent = orderedByProgressFollowupPortfolios[0].progressSinceStart;
				this.topStartDate = orderedByProgressFollowupPortfolios[0].startDate;
				this.bottomPercent = orderedByProgressFollowupPortfolios[orderedByProgressFollowupPortfolios.length - 1].progressSinceStart;
				this.bottomStartDate = orderedByProgressFollowupPortfolios[orderedByProgressFollowupPortfolios.length - 1].startDate;
			});

			this.$emitter.on(
				'login',
				async function (account) {
					this.account = account;
					//console.log(this.account);
					//console.log('Logged In!');
					if (store.state.accessToken === undefined) {
						VueCookieNext.setCookie('hasLoggedIn', true);
						await core.getAccessToken();
					}
				}.bind(this),
			),
				this.$emitter.on('logout', () => {
					//console.log('logging out');
					this.account = undefined;
				});
		},
		setup() {
			//console.log('Cover setup');
		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}
</style>
