<template>
    <div>
        <div class="row p-2">
            <div class="col-9"></div>
            <div class="col-1">
                <fa v-on:click="entering()" icon="plus-square" class="fas fa-plus-square fa-2x"></fa>
            </div>
        </div>

        <div v-if="state.entering" class="row p-2 text-center">
            <div class="col">
                <label class="text-left m-2">Name:</label>
                <input v-model="state.name" class="m-2 text-left">
            </div>
            <div class="col">
                <label class="text-left m-2">Exchanges:</label>
                <select v-model="state.exchanges" class="align-middle" multiple>
                    <option v-for="exchange in state.possibleExchanges" v-bind:key="exchange" v-bind:value="exchange">
                        {{ exchange }}
                    </option>
                </select>
            </div>
            <div class="col">
                <label class="text-left m-2">Repetition:</label>
                <input v-model="state.repetition" class="m-2 text-left">
            </div>
            <div class="col">
                <label class="text-left m-2">Sliding average:</label>
                <input v-model="state.slidingAverage" class="m-2 text-left">
            </div>
            <div class="col">
                <label class="text-left m-2">Strategy Type:</label>
                <select v-model="state.strategyType" class="align-middle">
                    <option v-for="strategyType in state.strategyTypes" v-bind:key="strategyType.key" v-bind:value="strategyType.key">
                        {{ strategyType.value }}
                    </option>
                </select>
            </div>
            <div class="col">
                <button type="button" class="btn btn-primary m-2 float-right" v-on:click="cancel()">Cancel</button>
                <button type="button" class="btn btn-primary m-2 float-right" v-on:click="add()">Add</button>
            </div>
        </div>
        
        <div class="row border">
            <div class="col p-2"><b>Name</b></div>
            <div class="col p-2"><b>Exchanges</b></div>
            <div class="col p-2"><b>Repetition</b></div>
            <div class="col p-2"><b>Sliding Average</b></div>
            <div class="col p-2"><b>Strategy Type</b></div>
        </div>
        <Strategy v-for="strategy in strategies"
                  :key="strategy.id"
                  :strategy="strategy"
                  :strategyId="strategy.id">
        </Strategy>
    </div>
</template>

<script>
    import { reactive } from 'vue';
    import $ from 'jquery';
    import store from '../store';
    import core from '../api/core';
    import Strategy from "../components/Strategy.vue";

    export default {
        name: 'Strategies',
        components: {
            Strategy
        },
        setup() {
            const state = reactive({
                entering: false,
                name: "",
                repetition: 10,
                slidingAverage: 10,
                exchanges: [],
                strategyType: 0,
                possibleExchanges: null,
                strategyTypes: null
            });

            state.possibleExchanges = store.state.exchanges;
            state.strategyTypes = store.state.strategyTypesTexts;

            fetchStrategies();

            var strategies;
            function fetchStrategies() {
                var ajaxSettingsStocks = core.buildAjaxSettings('strategies', false);
                $.getJSON(ajaxSettingsStocks, function (s) {
                    strategies = s;
                });
            }

            function entering() {
                state.entering = true;
            }

            function cancel() {
                state.entering = false;
            }

            function add() {
                var strategy = {
                    name: state.name,
                    slidingAverageWindow: parseInt(state.slidingAverage),
                    strategyType: state.strategyType,
                    repetition: parseInt(state.repetition),
                    exchanges: state.exchanges.join()
                };

                var ajaxSettings = core.buildAjaxSettings('strategies', false);
                ajaxSettings.type = 'POST';
                ajaxSettings.data = JSON.stringify(strategy);
                $.ajax(ajaxSettings, function (s) {
                    console.log(s);

                    this.fetchStrategies();
                    state.name = "";
                    state.repetition = 10;
                    state.slidingAverage = 10;
                    state.exchanges = [];
                    state.strategyType = 0;
                    state.entering = false;

                    //console.log('State:');
                    //console.log(state);
                });
            }

            return {
                entering,
                cancel,
                add,
                strategies,
                state
            }
        }
    }
</script>
