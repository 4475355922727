import { createApp /*, vue */} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Emitter from 'tiny-emitter'
import { VueCookieNext } from 'vue-cookie-next';
import { FontAwesomeIcon } from "@/font-awesome";

const app = createApp(App);
app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();

app.use(store)
	.use(router)
	.use(VueCookieNext)
	.component("fa", FontAwesomeIcon)
	.mount('#app');


VueCookieNext.config({ expire: '30d' });

store.state.emitter = app.config.globalProperties.$emitter;


