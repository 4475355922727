import { createRouter, createWebHistory } from 'vue-router';
import Portfolios from '../views/Portfolios.vue';
import Portfolio from "../views/Portfolio.vue";
import Strategies from "../views/Strategies.vue";
import Strategy from "../views/Strategy.vue";
import Stocks from "../views/Stocks.vue";
import StockGraph from "../views/StockGraph.vue";
import Cover from "../views/Cover.vue";
import Macro from "../views/Macro.vue";

const routes = [
	{
		path: '/',
		name: 'Cover',
		component: Cover
    },
	{
        path: '/:portfolioType',
        name: 'Portfolios',
        component: Portfolios
    },
    {
        path: '/portfolio/:id',
        name: 'Portfolio',
        component: Portfolio
    },
    {
        path: '/strategies',
        name: 'Strategies',
        component: Strategies
    },
    {
        path: '/strategy/:id',
        name: 'Strategy',
        component: Strategy
    },
    {
        path: '/stocks',
        name: 'Stocks',
        component: Stocks
    },
    {
        path: '/stockgraph',
        name: 'StockGraph',
        component: StockGraph
    },
    {
		path: '/macro',
		name: 'MacroData',
		component: Macro
    }];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;